<template>
  <div>
    <Breadcrumbs :main="$tc('models.serviceProvider.entity', 1)" :title="breadcrumbTitle"/>
    <b-container>
      <b-row>
        <b-col>
          <b-card class="mt-4">
            <b-card-body>
              <app-form ref="form" :fields="fields" :submitButton="submitButton" @submitted="handleSubmit"/>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import FormsMixin from 'mixins/FormsMixin';

export default {
  name: 'ServiceProviderForm',
  data() {
    return {
      modelName: 'ServiceProvider',
      fields: {
        header: {
          type: 'text',
          key: 'header',
          value: '',
          label: this.$t('models.serviceProvider.attributes.header'),
          required: true,
          classes: 'col-12 py-0',
          valid: false
        },
        image: {
          type: 'file',
          key: 'image',
          value: '',
          label: this.$t('models.serviceProvider.attributes.image'),
          acceptedFiles: 'image/*',
          required: false,
          valid: true,
          classes: 'col-12 py-0',
          fileType: 'image',
          origFile: null
        },
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.serviceProvider.attributes.email'),
          required: false,
          classes: 'col-12 py-0',
          valid: false
        },
        phoneNo: {
          type: 'tel',
          key: 'phoneNo',
          value: '',
          label: this.$t('models.serviceProvider.attributes.phoneNumber'),
          classes: 'col-12 py-0',
          valid: false,
          required: true,
          minLength: 9
        },
        address: {
          type: 'text',
          key: 'address',
          value: '',
          label: this.$t('models.serviceProvider.attributes.address'),
          required: true,
          classes: 'col-12 py-0',
          valid: false
        },
        latitude: {
          type: 'text',
          key: 'latitude',
          value: '',
          label: this.$t('models.serviceProvider.attributes.latitude'),
          classes: 'col-6 py-0',
          valid: true,
          required: false
        },
        longitude: {
          type: 'text',
          key: 'longitude',
          value: '',
          label: this.$t('models.serviceProvider.attributes.longitude'),
          classes: 'col-6 py-0',
          valid: true,
          required: false
        },
        locationImage: {
          type: 'file',
          key: 'locationImage',
          value: '',
          label: this.$t('models.serviceProvider.attributes.locationImage'),
          acceptedFiles: 'image/*',
          required: false,
          valid: true,
          classes: 'col-12 py-0',
          fileType: 'image',
          origFile: null
        },
        description: {
          type: 'rich-text',
          key: 'description',
          value: '',
          label: this.$t('models.serviceProvider.attributes.description'),
          required: true,
          classes: 'col-12 py-0',
          valid: false
        },
        consultants: {
          type: 'objectSearchableSelect',
          objectLabel: 'name',
          objectValue: 'id',
          allowMultiple: true,
          key: 'consultants',
          value: [],
          searchData: [],
          autocompleteAction: this.suggestConsultants,
          label: this.$t('models.serviceProvider.attributes.consultant'),
          required: false,
          valid: true,
          classes: 'col-12 py-0'
        },
        tagList: {
          type: 'searchableSelect',
          allowMultiple: false,
          taggable: true,
          key: 'tagList',
          value: [],
          searchData: [],
          autocompleteAction: this.suggestTags,
          label: this.$t('models.serviceProvider.attributes.category'),
          required: true,
          valid: false,
          classes: 'col-12 py-0'
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      record: 'serviceProvider',
      tagsList: 'tagsList',
      users: 'users'
    })
  },
  methods: {
    ...mapActions({
      getRecord: 'getServiceProvider',
      createRecord: 'createServiceProvider',
      updateRecord: 'updateServiceProvider',
      searchTags: 'searchTags',
      getUsers: 'getUsers'
    }),
    suggestTags(val) {
      this.searchTags({...this.options, filterBy: {key: val, model: 'ConsultingService'}}).then((response) => {
        this.fields.tagList.searchData = this.tagsList.items;
      });
    },
    suggestConsultants(val) {
      this.getUsers({ filterBy: { orAttributes: { name: val }} }).then(() => {
        this.fields.consultants.searchData = this.users.items.map((user) => {
          return { name: user.name, id: user.id };
        });
      });
    },
    createFormData(data) {
      const serviceProviderData = new FormData();
      for (const key in data) {
        if (key === 'consultants') {
          for(let i = 0; i < data[key].length; i++) 
            serviceProviderData.append(key + '[]', data[key][i].id);
        } else if (['image', 'location_image'].indexOf(key) > -1 && data[key] === '') {
          continue;
        } else {
          serviceProviderData.append(key, data[key]);
        }
      }
      return serviceProviderData;
    }
  },
  mixins: [FormsMixin]
};
</script>
